.portfolio-images h4 {
  margin: 0;
}
.portfolio-images p{
  text-align: center;
  margin-top: 20px;
}
.portfolio-images a{
  font-size: 25px;
}
