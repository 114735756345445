// Colors
$brand-blue-dark:        #26225b;
$brand-blue-light:       #5371a9;
$brand-orange:           #fbb040;
$brand-primary:          $brand-orange;
$brand-secondary:        $brand-blue-dark;
$gray-lighter:           #dcdce1;

// Fonts
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family:   'Marcellus', serif;
$headings-margin-bottom: 3rem;
$font-size-h1:           3.5rem;
$font-size-h2:           2rem;
$font-size-h3:           1.75rem;
$font-size-h4:           1.5rem;

// Options
$enable-rounded:         false;
$enable-flex:            false;

// Links
$link-hover-decoration:  none;
