@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans-v13-latin-300.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin-300.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
       url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('../fonts/open-sans-v13-latin-italic.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
       url('../fonts/open-sans-v13-latin-600.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin-600.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v13-latin-700.woff2') format('woff2'),
       url('../fonts/open-sans-v13-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  src: local('Marcellus'), local('Marcellus-Regular'),
       url('../fonts/marcellus-v4-latin-regular.woff2') format('woff2'),
       url('../fonts/marcellus-v4-latin-regular.woff') format('woff');
}

body {
  font-weight: 300;
}

img {
  max-width: 100%;
  height: auto;
}

.fs-0 {
  font-size: 0;
}

h1,
h2,
h3,
h4 {
  text-transform: uppercase;
  &.alt {
    font-family: $font-family-sans-serif;
  }
}

h1 {
  font-size: 2.5rem;

  @include media-breakpoint-up(sm) {
    font-size: 3.5rem;
  }
}

.text-secondary {
  color: $brand-secondary !important;
}
.bg-brand-primary {
  background-color: $brand-primary;
}
.bg-brand-secondary {
  background-color: $brand-secondary;
  color: #fff;
  text-align: center;
}

//Font Awesome
.fa{
  color: $brand-blue-dark;
  margin-right: 15px;
}

//Gravity Forms
.gform_wrapper .top_label .gfield_label{
  display: none;
}
body .gform_wrapper .top_label div.ginput_container {
  margin-top: 35px;
}
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    border: 3px solid $brand-blue-dark;
}
.gform_wrapper textarea.medium{
  border: 3px solid $brand-blue-dark;
}
.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input[type=submit]{
  color: #fff;
  background-color: #fbb040;
  border-color: #fbb040;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .5rem 1rem;
  font-size: 1rem;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
.gform_wrapper .gform_footer input.button:hover,
.gform_wrapper .gform_footer input[type=submit]:hover{
  color: #fff;
  background-color: #fa9b0e;
  border-color: #f99705;
}
