//Setting the Hero Image Background
.green-hero{
  height: 85vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

//Setting the Hero Image Text Using the One and Only Flex Box
.green-hero-content h1{
  position: absolute;
  top: 66vh;
  color: #fff;
}

//Trailing text color invert
.greentech_trailing{
  background: $brand-blue-dark;
  color: #fff;
}

//Repeater Feild H3 Bottom Margin
.greentech-products h3{
  margin-bottom: 1rem;
}
