//Protfolio
.commercial_portfolio{
  height: 72vh;
  position: relative;
}
.commercial_portfolio-content{
  padding-top: 26vh;
  padding-bottom: 30vh;
  text-align: center;
}
