// Styles for the gallery-single.php

//Hero
.gallery-hero{
  height: 50vh;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

//Centering up the portfolio text
.gallery-title-section{
  display: flex;
  justify-content: center;
  align-items: center;
  background: $brand-blue-dark;
  color: #fff;
  text-align: center;
}

//Padding the shizzzzzz outta that mug they call the h1
.gallery-title-section h1{
  margin: 3rem;
}

//gallery loop going all flex box on ya
.gallery-loop{
  display: block;
  background-color: #26225b;
  font-size: 0;
}

//gallery image size
.gallery-images{
  display: inline-block;
  width: 100%;
}
.gallery-images img{
  object-fit: fill;
  height: 100%;
  width: 100%;
}

//gallery image size mobile
@media screen and (min-width: 600px){
  .gallery-images{
    width: 50%;
  }
}
@media screen and (min-width: 980px){
  .gallery-images{
    width: 33.33%;
  }
}
