.banner {
  text-align: center;
  position: relative;
  z-index: 4;
}

.brand {
  display: block;
  width: 228px;
  height: 86px;
  margin: 0 auto;
  background: url(../images/MIDATLANTIC.jpg) no-repeat;
  background-size: cover;
  padding: 1rem;

  @include text-hide;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }
}
@include media-breakpoint-up(md) {
  .brand {
    margin: 0 0 -21px;
    width: 180px;
    height: 68px;
  }
}
@include media-breakpoint-up(lg) {
  .brand {
    margin-bottom: -26px;
    width: 228px;
    height: 86px;
  }
}

.nav-primary {
  display: none;

  ul li {
    display: block;
  }

  @include media-breakpoint-up(md){
    display: block;

    ul li {
      display: inline-block;
    }
  }
}

.nav-primary ul {
  display: block;
  padding: ($spacer / 2) 0;
  margin: 0;
  list-style: none;
  position: relative;
}
@include media-breakpoint-up(md) {
  .nav-primary ul {
    padding: 0;
  }
}
.nav-primary li {
  position: relative;
}
.nav-primary .nav > li {
  display: inline-block;
}
.nav-primary a {
  color: $brand-secondary;
  font-weight: 600;
  letter-spacing: -.03em;
  display: block;
  padding: 0.25rem;
  position: relative;
  text-transform: uppercase;
  transition: .15s;
}
@include media-breakpoint-up(lg) {
  .nav-primary a {
    padding: 0.8rem;
    font-size: 1.25rem;
  }
}
@include media-breakpoint-up(xl) {
  .nav-primary a {
    padding: 0.8rem 1rem;
  }
}
.nav-primary .menu-item-has-children .menu-toggle {
  display: inline-block;
  &:after {
    font-family: 'FontAwesome';
    content: "\f078";
    display: block;
    padding-left: ($spacer / 2);
  }
}
.nav-primary .menu-item-has-children .active > .menu-toggle:after {
  content: "\f077";
}
.nav-primary .menu-item > .active {
  color: $brand-primary;
}
.nav-primary a:hover,
.nav-primary .active a {
  color: $brand-primary;
  &.active {
    color: $brand-primary;
  }
}
.nav-primary .sub-menu {
  display: none;
  background: #fff;
  border: 2px solid $brand-secondary;
  padding-bottom: ($spacer / 2);
  max-height: 0;
  &.active {
    max-height: 55em;
  }
}
.nav-primary .sub-menu .sub-menu {
  border: none;
}
.nav-primary .sub-menu a {
  padding-top: ($spacer / 2);
  padding-bottom: ($spacer / 2);
  color: $brand-secondary;
}
.nav-primary .sub-menu .active > a,
.nav-primary .sub-menu a:hover {
  color: $brand-primary;
}
.nav-primary .menu-item-has-children > .sub-menu.active {
  z-index: 5;
  text-align: left;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 18em;
}
.nav-primary .menu-item-has-children > .sub-menu.active .sub-menu {
  position: relative;
  padding-left: ($spacer * 1.5);
}
