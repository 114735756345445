
//Home Hero Slider

//Get that image to fill it's space
.carousel-item{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @include media-breakpoint-up(md) {
    min-height: 45vh;

    img {
      width: 100%;
      height: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: 50vh;

    img {
      width: 100%;
      height: auto;
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 70vh;
  }

  @include media-breakpoint-down(sm){
    padding: 2rem 1rem;
  }
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: none;
}

.page-hero-content {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  width: 100%
}
.page-hero-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background-image: linear-gradient(to left, rgba($brand-blue-dark,.85) 0%, rgba($brand-blue-dark,.51) 32%, rgba($brand-blue-dark,.31) 49%, rgba($brand-blue-dark,.1) 89%, rgba($brand-blue-dark,.51) 100%);
}
.page-hero-content h3{
  position: relative;
  top: 30px;
  left: 70px;
}
.page-hero-content a{
  position: relative;
  top: 0px;
  right: 140px;
}
// Hero content media calls
@media screen and (max-width: 772px){
  .page-hero-content {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%
  }
  .page-hero-content h3{
    position: relative;
    top: 0px;
    left: 0px;
  }
  .page-hero-content a{
    position: relative;
    top: 0px;
    right: 0px;
  }
}

// Pushing the home about us image down
.home-about-img{
  margin-top: 50px;
}

//Services List Style
.services-list{
  font-size: 0;
}
.services-list .services-list-width{
  width: 100%;
  display: inline-block;

  img {
    max-width: none;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    &:last-of-type {
      transform: translateX(50%);
    }
  }

  @include media-breakpoint-down(xs) {
    &:last-of-type {
      transform: translateX(0);
    }
  }

  @include media-breakpoint-up(sm){
    width: 50%;
  }

  @include media-breakpoint-up(md){
    width: 20%;
  }
}




//Background image on the services section
.home-services{
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0px;
}

//Button Styles in the service sections
.home-services a{
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

//Portfolio size settings
.home-portfolio{
  height: auto;
}

.home-portfolio-images {
  font-size: 0;
}

.home-portfolio-image {
  display: inline-block;
  width: 100%;

  .image-wrapper {
    max-height: 250px;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      max-height: 280px;
    }

    @include media-breakpoint-up(md) {
      max-height: 260px;
    }

    @include media-breakpoint-up(lg) {
      max-height: 300px;
    }

  }

  @include media-breakpoint-up(md) {
    width: 33%;
  }
}
.home-portfolio .row{
  margin: 0;
}
.home-portfolio-h1{
  width: 100%;
  height: auto;
  margin: 30px 0;
  text-align: center;

}
.home-portfolio-h1 h1{
  margin: 0px 0;
  color: #000;
}
.home-portfolio-h1 h1:hover{
  text-decoration: underline;
}
.home-portfolio-h1 h3{
  margin: 0px 0;
}
.home-portfolio .col-lg-4{
  padding: 0 23px;
  width: 100%;
  margin-bottom: 50px;
}
.home-portfolio .col-lg-4 img{
  object-fit: fill;
  height: auto;
  width: 100%;
}

/* Media call for the images to make sure they do not stretch */
@media screen and (min-width: 991px){
  .home-portfolio .col-lg-4 img{
    height: 300px;
  }
}
