.content-info {
  background: $brand-secondary;
  color: #fff;
  padding-top: ($spacer * 4);
  padding-bottom: ($spacer * 3);
}

.content-info p {
  font-size: 1.25rem;
}
.content-info .list-social a {
  font-size: 1.8rem;
  color: #fff;
}

.content-info-bottom {
  padding-top: ($spacer * 4);
}
.content-info-bottom p {
  font-family: $headings-font-family;
  font-size: 1rem;
}

footer .textwidget h3 {
  margin-bottom: 0;
}
