// Page hero
.page-hero {
  position: relative;
}
.page-hero-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background-image: linear-gradient(to left, rgba($brand-blue-dark,.85) 0%, rgba($brand-blue-dark,.51) 32%, rgba($brand-blue-dark,.31) 49%, rgba($brand-blue-dark,.1) 89%, rgba($brand-blue-dark,.51) 100%);
}
.page-hero-content {
  position: relative;
  z-index: 3;
  text-align: center;
  color: #fff;
}
.page-hero-content .btn {
  @extend .btn-lg;
}

// Section spacing utilties
.section-mt {
  margin-top: 4rem;
}
.section-mb {
  margin-bottom: 4rem;
}
.section-pt {
  padding-top: 4rem;
}
.section-pb {
  padding-bottom: 4rem;
}
.section-pt-2x {
  padding-top: 8rem;
}
.section-pb-2x {
  padding-bottom: 8rem;
}

@include media-breakpoint-up(xl) {
  .section-pt-2x {
    padding-top: 16rem;
  }
  .section-pb-2x {
    padding-bottom: 16rem;
  }
}
